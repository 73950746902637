export interface eatItem {
  id: string;
  name: string;
  price: number;
  img: string;
  detail?: {
    link?: string;
  }
}

export const data: eatItem[] = [
  {
    id: '1',
    name: '馒头',
    price: 1,
    img: '/mt.png',
  },
  {
    id: '2',
    name: '茶叶蛋',
    price: 1,
    img: '/cyd.png',
  },
  {
    id: '3',
    name: '三鲜伊面',
    price: 2,
    img: '/sxym.png',
  },
  {
    id: '4',
    name: '沙县小吃拌面',
    price: 4,
    img: '/pxbm.png',
  },
  {
    id: '5',
    name: '冰露矿泉水',
    price: 0.8,
    img: '/bl.png',
  },
  {
    id: '6',
    name: '西北风',
    price: 0,
    img: '/xbf.png',
  },
  {
    id: '7',
    name: '窝窝头',
    price: 0.8,
    img: '/wwt.png',
  },
  {
    id: '8',
    name: '白粥',
    price: 1,
    img: '/bz.png',
  },
  {
    id: '9',
    name: '红薯',
    price: 1,
    img: '/hs.png',
  },
  {
    id: '10',
    name: '鸡蛋糕',
    price: 1,
    img: '/jdg.png',
  },
  {
    id: '11',
    name: '一盒米饭',
    price: 1,
    img: '/mf.png',
  },
  {
    id: '12',
    name: '蒸土豆',
    price: 0.5,
    img: '/td.png',
  },
];

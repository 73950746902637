import React, { useCallback, useState } from "react";
import "./App.scss";
import { EatwatBox } from "./components/EatwatBox";
import Footer from "./components/Footer";
import { data } from "./data";

function App() {
  const [isStart, setIsStart] = useState(false);
  const [list] = useState(data);

  const toggleStartButton = useCallback(() => {
    setIsStart(!isStart);
  }, [isStart]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-title">《穷人版·今天吃什么》</div>
        <EatwatBox list={list} speed={80} isRunning={isStart} />
        <div className="btn" onClick={toggleStartButton}>
          {isStart ? "暂停" : "开始"}
        </div>
      </header>
      <Footer />
    </div>
  );
}

export default App;

import "./index.scss";

const Footer = () => {
  return (
    <div className="footer">
      <p className="copyright">
        <span className="no-select">© 2016 - 2021</span>
        <span className="name">hzfe.org</span>
        <span className="links">
          <a href="https://mp.weixin.qq.com/s/fmNA4isEAQUyscWbf3BPJQ">
            关于我们
          </a>
          <br></br>
          <a href="https://m.weibo.cn/detail/4644498132960723">
            便宜大碗的食物不在图中？留言回复，我们加进去！
          </a>
        </span>
      </p>
    </div>
  );
};

export default Footer;

import { useCallback, useEffect, useReducer } from "react";
import { eatItem } from "../data";

interface EatwatBoxProps {
  list: eatItem[];
  isRunning: boolean;
  speed: number;
}

export function EatwatBox(props: EatwatBoxProps) {
  const { list, isRunning, speed } = props;

  const curIdxReducer = useCallback(
    function reducer(state: number, action: string) {
      if (action === "next") {
        return state < list.length - 1 ? state + 1 : 0;
      } else {
        return 0;
      }
    },
    [list]
  );

  const [curIdx, dispatch] = useReducer(curIdxReducer, 0);

  useEffect(() => {
    dispatch("reset");
  }, [list]);

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (isRunning) {
      timer = setInterval(() => {
        dispatch("next");
      }, speed);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [isRunning, speed]);

  return (
    <div className="eatwatBox">
      <div className="watermark">eat.hzfe.org</div>
      {list.map((item, index) => (
        <div
          className="eatwatBox-item"
          key={item.id}
          style={{ opacity: curIdx === index ? 1 : 0 }}
        >
          <div className="name">
            {item.name}/<small>{item.price} 元</small>
          </div>
          <img className="img" src={assets + item.img} alt={item.name} />
        </div>
      ))}
    </div>
  );
}
